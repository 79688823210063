import type { RouteComponentProps } from '@reach/router';
import CheckoutDisclosureReactified
  from '@svelte/reactify/checkout/CheckoutDisclosureReactified';
import { NavigationMenuQuery } from '@svelte/service/cms/graphql/gql';

import { Container } from 'components/atoms/layout/Container';
import { Heading } from 'components/atoms/typography';
import PageContentLayout from 'components/layouts/PageContentLayout';
import { makeClassNames } from 'lib/util';
import React, { useEffect } from 'react';
import { useAppNavTree } from 'state/context/nav';
import checkoutStore, { updateCheckoutInProgress } from 'state/stores/checkout';
import { get } from 'svelte/store';

import { CartItems } from './CartItems';
import { StepContentWrapper } from './Step';

type Props = RouteComponentProps &
  PropsWithChildren & {
    isCart: boolean;
    isOrderConfirmation: boolean;
    canEditItems?: boolean;
    footerNavigation: NavigationMenuQuery;
    title: string;
  };

export const CheckoutWrapper: React.FC<Props> = ({
  children,
  isCart,
  isOrderConfirmation,
  footerNavigation,
  canEditItems = false,
  title
}) => {
  useEffect(() => {
    if (!get(checkoutStore).checkoutInProgress) {
      updateCheckoutInProgress(true);
    }
  }, []);

  return (
    <CheckoutContainer
      title={title}
      footerNavigation={footerNavigation}
      isCart={isCart}
      isOrderConfirmation={isOrderConfirmation}
      canEditItems={canEditItems}
    >
      <div
        id="cart-items"
        className={makeClassNames(
          isCart ? '' : 'hidden lg:block',
          'pb-rhythm1'
        )}
      >
        <Container>
          {/* NOTE: This width matches the Checkout step max-w */}
          <div className="max-w-xl">
            <CartItems
              readOnly={!canEditItems}
              withAmounts={!isCart}
              className="lg:col-start-1 lg:row-start-2"
            />
          </div>
        </Container>
      </div>

      <StepContentWrapper>{children}</StepContentWrapper>
    </CheckoutContainer>
  );
};

export const CheckoutContainer: React.FC<Props> = ({
  canEditItems,
  children,
  footerNavigation,
  isCart,
  isOrderConfirmation,
  title
}) => {
  const appNavTree = useAppNavTree();

  return (
    <PageContentLayout
      footerNavigation={footerNavigation}
      withSubscriptionBanner={false}
    >
      {!isCart && !isOrderConfirmation && (
        <CheckoutDisclosureReactified
          readOnly={!canEditItems}
          appNavTree={appNavTree}
        />
      )}

      {/* Large min-height is to give whitespace and also make sure qty select option doesn't create overflow issues */}
      <div className="relative grid grid-cols-1 lg:grid-cols-[500px_1fr] xl:grid-cols-[600px_1fr]">
        <div className="lg:col-span-1 lg:col-start-2 lg:bg-pearl-200">
          <Container className="my-rhythm3">
            {/* <div className="mb-4 rounded-md bg-primary-200 p-8 ">
              <Heading
                level={3}
                bold
                size={TextSize.Pica}
                withoutSpacing
                className="mb-4"
                textCase={TextCase.Uppercase}
              >
                IMPORTANT NOTICE
              </Heading>
              <InterfaceText>
                We're closed during the holidays. Any orders placed during this
                period will be shipped on 6 January 2025.
              </InterfaceText>
            </div> */}

            <Heading level={1} withoutSpacing>
              {title}
            </Heading>
          </Container>
        </div>

        {children}
      </div>
    </PageContentLayout>
  );
};
